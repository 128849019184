.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  backdrop-filter: blur(3px);
  /* opacity: .3; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.modalContainer {
  width: 50%;

  border-radius: 10px;
  background-color: var(--corEscura);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  color: var(--corBranca);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.modalContainer .title {
  display: inline-block;
  letter-spacing: 0px;
  color: var(--corBranca);
  opacity: 1;
  text-align: left;
}

h3 {
  font-weight: bold;
  text-align: left;
  font-size: 1.4em;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  width: 100%;
  color: var(--corBranca);
  flex: 50%;
  text-align: left;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

label {
  margin-top: 25px;
  font-size: 18px;
}

textarea {
  border: 1px solid #828282;
  border-radius: 6px;
  width: 100%;
  height: 50%;
  max-height: 50%;
  margin-top: 10px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-right: 1%;
}

@media (orientation: portrait) {
  .modalContainer {
    width: 70%;
  }

  h3 {
    font-size: 3vw;
  }
}
