@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;500;700&display=swap');

html{
  scroll-behavior: smooth;
}




*{
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

::-webkit-scrollbar{width:5px}::-webkit-scrollbar-track{background:var(--corEscura);border-radius:10px}::-webkit-scrollbar-thumb{background: var(--corBranca);border-radius:10px}



body{
  max-width: 100vw;
  width: 100vw;
  overflow-x: hidden;

}


input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(0.8) brightness(100%) sepia(100%) saturate(10000%) hue-rotate(500deg);
  -webkit-filter: invert(0.8) brightness(100%) sepia(100%) saturate(10000%) hue-rotate(500deg);
}



select:hover
{
  cursor: pointer;
}






.mainContent{
  display: flex;
  background: var(--corEscura);
  overflow: hidden;
  
}

.content{
  background: linear-gradient(227.7deg, var(--corPrincipal)2.22%, var(--corPrincipal2) 97.93%);
  border-radius: 8px 0 0 0;
  overflow: hidden;
  width: calc(100vw - 250px);
  min-height: calc(100vh - 68px);
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  -webkit-border-radius: 8px 0 0 0;
  -moz-border-radius: 8px 0 0 0;
  -ms-border-radius: 8px 0 0 0;
  -o-border-radius: 8px 0 0 0;
}


.contentContainer{
 
  position: relative;
  width: 90%;
  min-height: 100%;
 
}



.contentContainer h2,
.contentContainer h4{
  color: var(--corEscura);
  font-weight: 900;

}

.active{
  background: var(--corPrincipal2);
}

.break-me{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 4px;
 
}

.page{
  padding: 4px 12px;
  border-radius: 5px;
  border: 1px solid black;
  margin-right: 5px;
 
  margin-top: 6px; 
  margin-bottom: 10px;
}

.ChartMensalidadeLines{
 
  height: 100%;
  width: 100%;
  
}


.ChartCrescimentoMensalidade{

  height: 100%;
  width: 100%;
  
}





@media(orientation: portrait){
  

  .content{
    width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}


  .ChartMensalidadeLines{
    height: 100%;
    width: 100%;
  }


  .ChartCrescimentoMensalidade{

    height: 100%;
    width: 100%;
    
  }


}





header{
  position: fixed;
  top: 0;
  background: var(--corEscura);
  left: 0;
  width: 100%;
  border-radius: 0 0 5px 0px ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 10px 10px;
  -webkit-border-radius: 0 0 5px 0px ;
  -moz-border-radius: 0 0 5px 0px ;
  -ms-border-radius: 0 0 5px 0px ;
  -o-border-radius: 0 0 5px 0px ;
  z-index: 15;
}



header .logo img{
  
  height: 50px;
  margin-left: 2.5vw;
}

header .menu{
  position: relative;
  display: flex;
  justify-content: center;
  
  align-items: center;
}




.switch {
  display: none;
  font-size: 17px;
  position: relative;
  width: 64px;
  bottom: 10px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #73C0FC;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  border-radius: 20px;
  left: 2px;
  bottom: 2px;
  z-index: 2;
  background-color: #e8e8e8;
  -webkit-transition: .4s;
  transition: .4s;
}

.sun svg {
  position: absolute;
  top: 6px;
  left: 36px;
  z-index: 1;
  width: 24px;
  height: 24px;
}

.moon svg {
  fill: #73C0FC;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  width: 24px;
  height: 24px;
}

/* .switch:hover */.sun svg {
  -webkit-animation: rotate 15s linear infinite;
          animation: rotate 15s linear infinite;
}

@-webkit-keyframes rotate {
 
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
 
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* .switch:hover */.moon svg {
  -webkit-animation: tilt 5s linear infinite;
          animation: tilt 5s linear infinite;
}

@-webkit-keyframes tilt {
 
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }

  75% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes tilt {
 
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }

  75% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.input:checked + .slider {
  background-color: #183153;
}

.input:focus + .slider {
  -webkit-box-shadow: 0 0 1px #183153;
          box-shadow: 0 0 1px #183153;
}

.input:checked + .slider:before {
  -webkit-transform: translateX(30px);
      -ms-transform: translateX(30px);
          transform: translateX(30px);
}







header .menu .MenuBar{
  display: none;
}

header .menu a{
 font-size: 0.75em;
 font-weight: 600;
 text-decoration: none;
 color: var(--corBranca);
 margin: 0 10px;
 padding: 5px 10px;
 text-transform: uppercase;
 transition: all 0.3s ease-in-out ;
 -webkit-transition: all 0.3s ease-in-out ;
 -moz-transition: all 0.3s ease-in-out ;
 -ms-transition: all 0.3s ease-in-out ;
 -o-transition: all 0.3s ease-in-out ;
}

header .menu a:hover{
 
  color: var(--azulEsverdeado);
  border-bottom: 1px solid var(--corBranca);
  
}


header .btn{
  font-size: 25px;
  cursor: pointer;
  color: #fff;
  display: none;
}

header{
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.2);
}







.CopyrightText{
  font-size: 0.7em;
}


/* for react-paginate/ start */
.break-me{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 4px;
 
}
.filepond--item {
  width: calc(33% - 0.5em);
}

.pagination {
  justify-content: center;
  display: flex;
  padding: 0 30px ;
  z-index: 1;
  list-style: none;
}

.page-item .page-link {
  position: relative;
  display: block;
  margin: 0 2px;
  border: 1px solid #cccccc;
  padding: 5px 10px;
  border-radius: 5px;
  color: #007bff;
  text-decoration: none;
}

.page-item a.page-link:hover {
  background-color: #cccccc;
}

.page-item.active .page-link {
  color: var(--corBranca);
  background-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
}

.buttonPage {
  display: flex;
  justify-content: center;
  transition: all .3s ease-in-out;
  font-size: 17px;
  padding: 0.5rem 2rem;
  border: transparent;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.4);
  background: var(--azulEsverdeado);
  color: var(--corEscura);
  text-decoration: none;
  font-weight: 700;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}
 
 .buttonPage:hover {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, var(--azulEsverdeado) 0%, rgba(0,212,255,1) 100%);
  cursor: pointer;
  color: var(--corEscura);
 }
 
 .buttonPage:active {
  transform: translate(0em, 0.2em);
 }


 .linkPage{
  position: relative;
 
 
  text-decoration: none;
  font-weight: 700;
  color: var(--corEscura);
 }


 .linkPage:hover{
  text-decoration: none;
  color: var(--corEscura);
 }





 
 .buttonPage {
  display: inline-flex;
  justify-content: center;
  max-width: 100%;
  white-space: nowrap;
  line-height: 1.2;
  padding: 0.5rem .75rem;
  
  background-color: var(--azulEsverdeado);
  color: var(--corEscura);
  font-family: Arial;
  text-decoration: none;
}




.active{
  background: var(--corPrincipal);
 }

 .active:hover{
  background: var(--corPrincipal);
  color: var(--corEscura);
 }


 /* for react-paginate/ end */



.spanTextarea{
  transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
}


textarea:valid ~.spanTextarea,
textarea:focus ~.spanTextarea{
  color: var(--azulEsverdeado);
 
}

@media(orientation: portrait){
 
  header{
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.2);
  }


  header .menu .items{
    display: flex;
  }


  header .menu a{
    display: none;
    font-size: 0.6em;
    margin: 0 5px;
    padding: 2px;
  }


  header .menu a:hover{
    color: var(--azulEsverdeado);
    border: 0;
    transition: all .3s ease-in-out;
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}

  
header .menu .MenuBar{
  display: block;
  font-size: 2em;
  
}


header .menu .MenuBar:active{
  color: var(--corPrincipal);
}

  .CopyrightText{
    font-size: 0.6em;

}



.logout{
  display: block;
}


  
.buttonPage {
  display: inline-flex;
  justify-content: center;
  max-width: 100%;
  white-space: nowrap;
  line-height: 1;
  padding: 1.7vw 1.3vw;
  font-size: 3vw;
  background-color: var(--azulEsverdeado);
  color: var(--corBranca);
  font-family: Arial;
  text-decoration: none;
}

.active{
  background: var(--corPrincipal);
 }

 .active:hover{
  background: var(--corPrincipal);
  color: var(--corBranca);

 }

   .switch {
    display: none;
    font-size: 17px;
    position: relative;
    width: 64px;
    bottom: 15px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #73C0FC;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 30px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    border-radius: 20px;
    left: 2px;
    bottom: 2px;
    z-index: 2;
    background-color: #e8e8e8;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .sun svg {
    position: absolute;
    top: 6px;
    left: 36px;
    z-index: 1;
    width: 24px;
    height: 24px;
  }
  
  .moon svg {
    fill: #73C0FC;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
    width: 24px;
    height: 24px;
  }
  
  /* .switch:hover */.sun svg {
    -webkit-animation: rotate 15s linear infinite;
            animation: rotate 15s linear infinite;
  }

}















