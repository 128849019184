@import url("primereact/resources/primereact.css");
@import url("primereact/resources/themes/lara-light-indigo/theme.css");
body {
  margin: 0;
  padding: 0;
  background-color: var(--unnamed-color-f5f6fa) 0% 0% no-repeat padding-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  border-radius: 50% !important;
}