.selectArea{
    margin: 0 0 30px 0;
    border-radius:10px ;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: var(--corEscura);
    -webkit-border-radius:10px ;
    -moz-border-radius:10px ;
    -ms-border-radius:10px ;
    -o-border-radius:10px ;
}

.selectAreaDiv select{
    margin: 0px 0px 30px 15px;
}

.selectAreaDiv select option{
    font-size: 0.75em;
}


.Legenda{
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.containerCheckbox span{
  margin: 0 0 0 5px;
  font-weight: 700;
  font-size: .9em;
}


/* Green glow checkbox made by: csozi | Website: www.csozi.hu*/
.texto{
    display: flex;
    align-items: flex-end;
    justify-content: center;
}


.containerCheckbox input {
    position: relative;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
   
  }



  .containerCheckbox {
    position: relative;
   
    
    user-select: none;
    padding-bottom: 10px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }


input[type="checkbox"]:hover{
    cursor: pointer;
}  

  
  .checkmark {
    position: relative;
    top: 0;
    cursor: pointer;
    left: 0;
    height: 1em;
    width: 1em;
    border-radius: 1rem;
    background-color: #DC1076;
    transition: 0.1s;
  }
  
  .containerCheckbox input:checked ~ .checkmark {
    background-color: #00de00;
    box-shadow: 0 0 10px #00ff00;
  }







  
  .checkmarker {
    display: block;
    width: 15px;
    height: 15px;
    background-color: #ddd;
    border-radius: 5px;
    position: relative;
    transition: background-color 0.4s;
    overflow: hidden;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
   
   #checke:checked ~ .checkmarker {
    background-color: #08bb68;
   }
   
   .checkmarker::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 10px;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(40deg) scale(10);
    opacity: 0;
    transition: all 0.4s;
   }
   
   #checke:checked ~ .checkmarker::after {
    opacity: 1;
    transform: translate(-50%, -50%) rotateZ(40deg) scale(1);
   }

@media(orientation: portrait){
.selectAreaDiv select{
    margin: 10px 0px 10px 15px;
}


.containerCheckbox {
    padding-bottom: 10px;
    align-items: center;
    justify-content: end;
    bottom: 20px;
}



}